import React from 'react'

import './style.css'

function Footer() {
    return (
        <footer>
            <p>Made with ♥ <a href="https://ucsddesign.co">Design Co</a></p>
            <a>hello@ucsddesign.co</a>
        </footer>
    )
}

export default Footer
